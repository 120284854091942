export const getStrapiImageUrl = (url: string): string => {
  return `${process.env.GATSBY_STRAPI_URL}${url}`
}

export const DEFAULT_LOCALE = "en"

export const getPathForLocale = (locale: string, path: string | null, forceLocale = false): string => {
  path = path ?? ''

  if (!forceLocale && locale === DEFAULT_LOCALE) {
    return `${(path.startsWith('/')) ? '' : '/'}${path}`
  }

  return `/${locale}${(path.startsWith('/')) ? '' : '/'}${path}`
}

export const getAbsoluteUrl_CLIENT_ONLY = (href: string): string => {
  return new URL(href, window.location.href).href
}

export const mergeClassNames = (...classNames: (string | undefined | null)[]): string => {
  const actualNames = classNames.filter((name) => name !== undefined && name !== null && name !== '')
  return actualNames.join(' ')
}

export const pathify = function() { //factory function for new pathify
  /*
   * from server class  tainted.Pathification(object):
  """
  Normalise a string for use in eg in an (unescaped) URL or anywhere else that we want a very tame string
  Replacement for original which would reject strings with chars it didn't know about  -this version just strips them
  Note: caution should be exercised when applying this routine to keys (or anything else unique).
      There is no guarauntee that two unique strings won't pathify to the same string !! (eg we simply drop single quote)

  """
  IF MODIFYING THIS CODE -YOU MUST ALSO MODIFY THE SERVER AND kindo_webui3 VERSIONS
   */
  const subs: [RegExp, string][] = [
    [new RegExp("-+","g")," "], /*dashes become one space*/
    [new RegExp(" +","g"),"_"], /*one or more spaces reduced to single underscore*/
    [new RegExp("&","g"),"_and_"], /*ampersand to _and_ */
    [new RegExp("@","g"),"_at_"],  /* /@ to _at_*/
    [new RegExp("\\.","g"),"_dot_"],  /* . to _dot_  (note not identical to python -extra espace char needed) */
    [new RegExp("[^a-zA-Z0-9_]+","g"),""]  /* (finally  -anything other than alphanum and underscore removed*/
  ];
  return function(strDirty: string): string {
    let strMut = strDirty; //could not bring myself to repeatedly mutate the argument
    for (let i=0;i<subs.length;i++){
      const sub = subs[i];
      strMut = strMut.replace(sub[0],sub[1]);
    }
    return strMut.toLowerCase();
  };
}();


interface triggerGtagEventProps {
  action: string,
  category?: string,
  label?: string,
  value?: string
}
export const triggerGtagEvent = ({action, category, label, value}: triggerGtagEventProps): void => {
  if (window.gtag) {
    window.gtag("event", action, {
      'event_category': category,
      'event_label': label,
      'value': value
    })
  }
}