import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import * as styles from "./Footer.module.scss"
import { getPathForLocale, mergeClassNames } from "../../../util/util"
import { IInternalPage } from "../../../model/IInternalPage"
import { useCurrentLocale } from "../../../hook/locale"
import { StaticImage } from "gatsby-plugin-image"
import { BasicPhoneDetails } from "../../BasicPhoneDetails"
import { IPhoneNumber } from "../../../model/IPhoneNumber"
import { IMetadata } from "../../../model/IMetadata"
import { SocialIcons } from "../../social/SocialIcons"

interface QuickLinkType {
  id: string,
  title: string,
  internalPageLink: {
    slug: string | null
  } | null,
  externalPageLink: string | null
}

interface QuickLinkSectionType {
  links: QuickLinkType[]
}

export interface FooterProps {
  aboutEzlunch: string,
  aboutEzlunchPage: IInternalPage,
  socialsPitch: string,
  kindoEnquiriesUrl: string,
  quickLinkSections: QuickLinkSectionType[],
  pageSeo: IMetadata
}
export const Footer: React.FC<FooterProps> = ({aboutEzlunch, aboutEzlunchPage, kindoEnquiriesUrl, socialsPitch, quickLinkSections, pageSeo}) => {
  return (
    <div className={styles.Footer}>
      <AboutEzlunchSection aboutEzlunch={aboutEzlunch} aboutEzlunchPage={aboutEzlunchPage} />
      <QuickLinksSection quickLinksSections={quickLinkSections} kindoEnquiriesUrl={kindoEnquiriesUrl} />

      <div className={mergeClassNames(styles.section, styles.DownloadApp)}>
        <div className={styles.sectionContent}>
          <div className={styles.heading}>download our app</div>
          <div className={styles.body}>
            <div>Fast ordering, on the go!</div>
            <div className={styles.appBadgeIconContainer}>
              <div className={styles.appBadgeIcon} onClick={() => window.open('https://apps.apple.com/nz/app/mykindo/id1445622107', "_blank")}>
                <StaticImage src="../../../images/appstore_badge.png" alt="apple" quality={100} />
              </div>
              <div className={styles.appBadgeIcon} onClick={() => window.open('https://play.google.com/store/apps/details?id=nz.co.tgcl.mykindo&hl=en_NZ&gl=US', "_blank")}>
                <StaticImage src="../../../images/googlestore_badge.png" alt="android" quality={100} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConnectSection socialsPitch={socialsPitch} pageSeo={pageSeo} />
    </div>
  )
}

interface AboutEzlunchSectionProps {
  aboutEzlunch: string,
  aboutEzlunchPage: IInternalPage
}
const AboutEzlunchSection: React.FC<AboutEzlunchSectionProps> = ({aboutEzlunch, aboutEzlunchPage}) => {
  const currentLocale = useCurrentLocale()

  return (
    <div className={mergeClassNames(styles.section, styles.AboutEzlunch)}>
      <div className={styles.sectionContent}>
        <div className={styles.heading}>more about ezlunch</div>
        <div className={styles.body}>
          <div>
            {aboutEzlunch}
          </div>

          <div className={styles.fullStoryLink}>
            <Link to={getPathForLocale(currentLocale, aboutEzlunchPage.slug)}>Read our story</Link>
          </div>
        </div>
      </div>

      <div className={styles.sectionFooter}>
        <span>Copyright TGCL - The Growth Collective Ltd {new Date().getFullYear()}</span>
      </div>
    </div>
  )
}

interface QuickLinksSectionProps {
  quickLinksSections: QuickLinkSectionType[],
  kindoEnquiriesUrl: string,
}
const QuickLinksSection: React.FC<QuickLinksSectionProps> = ({quickLinksSections, kindoEnquiriesUrl}) => {
  return (
    <div className={mergeClassNames(styles.section, styles.QuickLinks)}>
      <div className={styles.sectionContent}>
        <div className={styles.heading}>quicklinks</div>
        <div className={styles.body}>
          <div className={styles.quickLinks}>
            <QuickLinksLinkSection links={quickLinksSections[0].links} />
            {(quickLinksSections.length > 1) ? <QuickLinksLinkSection links={quickLinksSections[1].links} /> : undefined}
            {(quickLinksSections.length > 2) ? <QuickLinksLinkSection links={quickLinksSections[2].links} /> : undefined}
            {(quickLinksSections.length > 3) ? <QuickLinksLinkSection links={quickLinksSections[3].links} /> : undefined}
          </div>

          <div className={styles.kindoEnquiries} onClick={() => window.open(kindoEnquiriesUrl, "_self")}>
            Enquire about<br/>Kindo online school<br/>payment system

            <StaticImage className={styles.kindoLogo} src="../../../images/kindo_logo.png" alt="kindo" quality={100}/>
          </div>
        </div>
      </div>
      <div className={styles.sectionFooter}/>
    </div>
  )
}

const QuickLinksLinkSection: React.FC<QuickLinkSectionType> = ({links,}) => {
  return (
    <div className={mergeClassNames(styles.quickLinkSection, styles.withUnderline)}>
      {links.map((link) => (
        <QuickLink key={`link-${link.id}`} {...link}/>
      ))}
    </div>
  )
}
const QuickLink: React.FC<QuickLinkType> = ({title, internalPageLink, externalPageLink}) => {
  const currentLocale = useCurrentLocale()

  return (
    <div className={styles.quickLink}>
      {(internalPageLink !== null) ? (
        <Link to={getPathForLocale(currentLocale, internalPageLink.slug)}>{title}</Link>
      ) : (
        <a href={externalPageLink ?? undefined}>{title}</a>
      )}
    </div>
  )
}

interface ConnectSectionData {
  strapi: {
    globalConfig: {
      localNumber: IPhoneNumber,
      nationalNumber: IPhoneNumber,
      helpdeskEmail: string
    }
  }
}
interface ConnectSectionProps {
  socialsPitch: string,
  pageSeo: IMetadata
}
const ConnectSection: React.FC<ConnectSectionProps> = ({socialsPitch, pageSeo}) => {
  const data = useStaticQuery<ConnectSectionData>(connectSectionQuery)

  const {localNumber, nationalNumber, helpdeskEmail} = data.strapi.globalConfig

  return (
    <div className={mergeClassNames(styles.section, styles.Connect)}>
      <div className={styles.sectionContent}>
        <div className={styles.heading}>connect</div>
        <div className={styles.body}>
          <BasicPhoneDetails localNumber={localNumber} nationalNumber={nationalNumber}/>
          <div>
            <a href={`mailto:${helpdeskEmail}`}>{helpdeskEmail}</a>
          </div>
        </div>

        <div className={mergeClassNames(styles.heading, styles.secondHeading)}>share the love</div>
        <div className={styles.body}>{socialsPitch}</div>
      </div>

      <div className={styles.sectionFooter}>
        <SocialIcons pageSeo={pageSeo} />
      </div>
    </div>
  )
}

const connectSectionQuery = graphql`
query FooterConnectSectionQuery {
  strapi {
    globalConfig {
      localNumber {
        ...phoneNumberFields
      }
      nationalNumber {
        ...phoneNumberFields
      }
      helpdeskEmail
    }
  }
}
`

export const footerFragment = graphql`
  fragment footerFragment on Strapi_ComponentSubcomponentsFooter {
    socialsPitch
    aboutEzlunch
    aboutEzlunchPage {
      slug
    }
    kindoEnquiriesUrl
    quickLinkSections:QuickLinkSection {
      links: QuickLink {
        externalPageLink
        id
        title
        internalPageLink {
          slug
        }
      }
    }
  }
`