import React, { useCallback } from "react"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { ShareIconType } from "../ShareIconType"

export const LinkedinShareIcon: React.FC<ShareIconProps> = ({size = 20, className, onClick, scaleOnTouchScreen=true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    const currentUrlEncoded = encodeURIComponent(window.location.origin)

    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrlEncoded}`

    logShareClick(ShareIconType.Linkedin)
    window.open(url, '_blank')
  }, [onClick])

  return (
    <FaLinkedin size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Share on LinkedIn'/>
  )
}