import React, { useEffect, useState } from "react"
import { BodyStartPortal } from "../../BodyStartPortal"
import { NavPageLinkData, NavPageLinksData, NavPhoneData } from "./Nav"
import * as styles from "./NavDrawer.module.scss"
import { getPathForLocale, mergeClassNames } from "../../../util/util"
import { FadeIn } from "../../FadeIn"
import { CSSTransition } from "react-transition-group"
import CrossIcon from "../../../images/cross_icon.svg"
import { Link } from "gatsby"
import { useAppContext } from "../../AppContext"

interface NavDrawerProps {
  links: NavPageLinksData,
  phone: NavPhoneData,
  onHide: VoidFunction
}
export const NavDrawer: React.FC<NavDrawerProps & {shown: boolean}> = ({ shown, ...props }) => {
  return (
    <>
      {(shown) ? (
        <BodyStartPortal>
          <FadeIn style={{zIndex: 100}}>
            <div
              className={mergeClassNames(styles.NavDrawer, (shown) ? styles.shown : null)}
              onClick={props.onHide}
            >
              <div className={styles.closeButton} onClick={props.onHide}>
                <CrossIcon/>
              </div>

              <NavDrawerContent {...props}/>
            </div>
          </FadeIn>
      </BodyStartPortal>
      ) : undefined}
    </>
  )
}

const NavDrawerContent: React.FC<NavDrawerProps> = ({links, phone}) => {
  const [mounted, setMounted] = useState<boolean>(false)
  const {currentLocale} = useAppContext()

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className={styles.contentContainer} onClick={e => e.stopPropagation()}>
      <CSSTransition
        in={mounted}
        timeout={300}
        classNames={{
          enter: styles.itemEnter,
          enterActive: styles.itemEnterActive,
          enterDone: styles.itemEnterDone,
          exit: styles.itemExit,
          exitActive: styles.itemExitActive
        }}
      >
        <Link to={getPathForLocale(currentLocale, "")} className={styles.item}>Home</Link>
      </CSSTransition>

      {links.navLinks.map((link, index) => (
        <CSSTransition
          in={mounted}
          key={`${link.page.slug ?? ''}-${index}`}
          timeout={300}
          classNames={{
            enter: styles.itemEnter,
            enterActive: styles.itemEnterActive,
            enterDone: styles.itemEnterDone,
            exit: styles.itemExit,
            exitActive: styles.itemExitActive
          }}
        >
          <NavDrawerLink link={link}/>
        </CSSTransition>
      ))}

      <CSSTransition
          in={mounted}
          timeout={300}
          classNames={{
            enter: styles.itemEnter,
            enterActive: styles.itemEnterActive,
            enterDone: styles.itemEnterDone,
            exit: styles.itemExit,
            exitActive: styles.itemExitActive
          }}
        >
        <Link to={getPathForLocale(currentLocale, "about")} className={styles.item}>Our Story</Link>
      </CSSTransition>

      <CSSTransition
          in={mounted}
          timeout={300}
          classNames={{
            enter: styles.itemEnter,
            enterActive: styles.itemEnterActive,
            enterDone: styles.itemEnterDone,
            exit: styles.itemExit,
            exitActive: styles.itemExitActive
          }}
        >
        <Link to={getPathForLocale(currentLocale, "news")} className={styles.item}>News</Link>
      </CSSTransition>

      <CSSTransition
          in={mounted}
          timeout={300}
          classNames={{
            enter: styles.itemEnter,
            enterActive: styles.itemEnterActive,
            enterDone: styles.itemEnterDone,
            exit: styles.itemExit,
            exitActive: styles.itemExitActive
          }}
        >
          <a href='https://kindo.co.nz/joinourteam/' target='_blank' rel="noreferrer" className={styles.item}>Join Our Team</a>
      </CSSTransition>

      <CSSTransition
          in={mounted}
          timeout={300}
          classNames={{
            enter: styles.itemEnter,
            enterActive: styles.itemEnterActive,
            enterDone: styles.itemEnterDone,
            exit: styles.itemExit,
            exitActive: styles.itemExitActive
          }}
        >
        <Link to={getPathForLocale(currentLocale, "contact")} className={styles.item}>Contact</Link>
      </CSSTransition>

      <CSSTransition
        in={mounted}
        timeout={500}
        classNames={{
          enterActive: styles.phoneDetailsEnterActive,
          enterDone: styles.phoneDetailsEnterDone
        }}
      >
        <div className={styles.phoneDetails}>
          <div>Any questions? Give us a call!</div>
          <div><a href={`tel:${phone.nationalNumber.callableNumber}`}>{phone.nationalNumber.userVisible}</a></div>
        </div>
      </CSSTransition>
    </div>
  )
}

interface NavDrawerLinkProps {
  link: NavPageLinkData,
  className?: string
}
const NavDrawerLink: React.FC<NavDrawerLinkProps> = ({link, className}) => {
  const {currentLocale} = useAppContext()
  return (
    <Link
      to={getPathForLocale(currentLocale, link.page.slug)}
      className={mergeClassNames(styles.item, className)}
    >{link.title}</Link>
  )
}