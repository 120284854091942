// extracted by mini-css-extract-plugin
export var MyKindoLogin = "MyKindoLogin-module--MyKindoLogin--iMHu0";
export var RaisedButton = "MyKindoLogin-module--RaisedButton--c9mc7";
export var minimised = "MyKindoLogin-module--minimised--1I3MP";
export var maximised = "MyKindoLogin-module--maximised--_O4TI";
export var clipon = "MyKindoLogin-module--clipon--1yAHW";
export var clipImage = "MyKindoLogin-module--clipImage--2bBbR";
export var clipContent = "MyKindoLogin-module--clipContent--b_4Xl";
export var clipLogo = "MyKindoLogin-module--clipLogo--XxJC-";
export var content = "MyKindoLogin-module--content--cH0RB";
export var subtitle = "MyKindoLogin-module--subtitle--iXkyj";
export var bold = "MyKindoLogin-module--bold--19pB1";