import React from "react"
import * as styles from "./FadeIn.module.scss"

interface FadeInProps {
  duration?: number,
  delay?: number,
  style?: React.CSSProperties
}
export const FadeIn: React.FC<FadeInProps> = ({children, duration= 300, delay = 0, style}) => {
  return (
    <div
      className={styles.FadeIn}
      style={{
        ...(style ?? {}),
        animationDuration: `${duration}ms`,
        animationDelay: `${delay}ms`
      }}
    >
      {children}
    </div>
  )
}