import React from "react"

export interface AppContext {
  currentLocale: string,
  currentSlug: string | null
}

const appContext = React.createContext<AppContext | null>(null)

export const AppContextProvider: React.FC<{context: AppContext}> = ({children, context}) => {
  return (
    <appContext.Provider value={context}>
      {children}
    </appContext.Provider>
  )
}

export const useAppContext = (): AppContext => {
  const ctx = React.useContext(appContext)
  if (ctx === null) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useAppContext must be used within an AppContextProvider.')
  }
  return ctx
}