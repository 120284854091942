import { ShareIconType } from "../ShareIconType"
import { triggerGtagEvent } from "../../../util/util"

export interface ShareIconProps {
  size?: number,
  className?: string,
  onClick?: VoidFunction,
  scaleOnTouchScreen?: boolean
}

export const TOUCHSCREEN_SIZE_SCALE_AMOUNT = 1.2

export const isTouchScreen = (): boolean => {
  return (typeof window !== 'undefined') && window.matchMedia("(hover: none)").matches
}

export const getActualSize = (baseSize: number, scaleOnTouchScreen: boolean): number => {
  return (scaleOnTouchScreen && isTouchScreen()) ? baseSize * TOUCHSCREEN_SIZE_SCALE_AMOUNT : baseSize
}

export const logShareClick = (shareType: ShareIconType): void => {
  triggerGtagEvent({
    action: "click",
    category: "share_icon",
    label: shareType
  })
}