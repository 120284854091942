// extracted by mini-css-extract-plugin
export var Footer = "Footer-module--Footer--3mrmS";
export var section = "Footer-module--section--3e-bV";
export var QuickLinks = "Footer-module--QuickLinks--20AOB";
export var body = "Footer-module--body--3jMEJ";
export var kindoEnquiries = "Footer-module--kindoEnquiries--19wf8";
export var DownloadApp = "Footer-module--DownloadApp--2Xy-T";
export var appBadgeIconContainer = "Footer-module--appBadgeIconContainer--Yl99U";
export var appBadgeIcon = "Footer-module--appBadgeIcon--1qo55";
export var RaisedButton = "Footer-module--RaisedButton--3BswL";
export var sectionContent = "Footer-module--sectionContent--l3SeI";
export var heading = "Footer-module--heading--Oh-H8";
export var sectionFooter = "Footer-module--sectionFooter--3SYDR";
export var AboutEzlunch = "Footer-module--AboutEzlunch--2MxTF";
export var fullStoryLink = "Footer-module--fullStoryLink--3efPp";
export var quickLinks = "Footer-module--quickLinks--IK_ck";
export var quickLinkSection = "Footer-module--quickLinkSection--JTTxY";
export var quickLink = "Footer-module--quickLink--3nki1";
export var withUnderline = "Footer-module--withUnderline--36_bk";
export var kindoLogo = "Footer-module--kindoLogo--3JMws";
export var Connect = "Footer-module--Connect--19etK";
export var secondHeading = "Footer-module--secondHeading--3CgX_";