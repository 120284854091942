import React, { useCallback } from "react"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import { FACEBOOK_APP_ID } from "../../../config"
import { ShareIconType } from "../ShareIconType"

export const FacebookShareIcon: React.FC<ShareIconProps> = ({size = 20, className, onClick, scaleOnTouchScreen=true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    const currentUrlEncoded = encodeURIComponent(window.location.origin)

    const url = `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=page&href=${currentUrlEncoded}&redirect_uri=${currentUrlEncoded}`

    logShareClick(ShareIconType.Facebook)
    window.open(url, '_self')
  }, [onClick])

  return (
    <FaFacebook size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Share on Facebook'/>
  )
}