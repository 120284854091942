import React, { useCallback } from "react"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaReddit } from "@react-icons/all-files/fa/FaReddit"
import { ShareIconType } from "../ShareIconType"

interface RedditShareIconProps extends ShareIconProps {
  title?: string
}
export const RedditShareIcon: React.FC<RedditShareIconProps> = ({size = 20, className, onClick, title, scaleOnTouchScreen=true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    const currentUrlEncoded = encodeURIComponent(window.location.origin)
    let titlePart = ''
    if (title) {
      titlePart = `&title=${encodeURIComponent(title)}`
    }

    const url = `https://www.reddit.com/submit?url=${currentUrlEncoded}${titlePart}`

    logShareClick(ShareIconType.Reddit)
    window.open(url, '_blank')
  }, [onClick])

  return (
    <FaReddit size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Share on Reddit'/>
  )
}