import React from "react"
import { Helmet } from "react-helmet"
import * as styles from "./Layout.module.scss"
import Nav from "./nav/Nav"
import { Footer } from "./footer/Footer"
import { graphql, useStaticQuery } from "gatsby"
import { IMetadata } from "../../model/IMetadata"

interface LayoutProps {
  pageSeo: IMetadata
}
const Layout: React.FC<LayoutProps> = ({children, pageSeo}) => {
  const footerData = useStaticQuery(layoutQuery);

  return (
    <div className={styles.Layout}>
      <Helmet>
        <meta charSet='utf-8' />
        <meta httpEquiv='cache-control' content='no-cache' />
        <meta httpEquiv='expires' content='0' />
        <meta httpEquiv='pragma' content='no-cache' /> 
        {/* <meta name="robots" content="noindex" /> */}
      </Helmet>
      <Nav/>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P86CV2K" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe></noscript>
      <main>
        {children}

        <Footer pageSeo={pageSeo} {...footerData.strapi.globalConfig.footer} />
      </main>
    </div>
  )
}

export default Layout


const layoutQuery = graphql`
query LayoutQuery {
  strapi {
    globalConfig {
      footer {
        ...footerFragment
      }
    }
  }
}
`