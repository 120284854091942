import React, { useCallback } from "react"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { ShareIconType } from "../ShareIconType"

interface EmailShareIconProps extends ShareIconProps {
  subject?: string,
  body?: string
}
export const EmailShareIcon: React.FC<EmailShareIconProps> = ({size = 20, className, onClick, subject, body, scaleOnTouchScreen = true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    let subjectPart = ''
    if (subject) {
      subjectPart = `&subject=${encodeURIComponent(subject)}`
    }

    let bodyPart = window.location.href
    if (body) {
      bodyPart = `${body}\n${bodyPart}`
    }

    const url = `mailto:?body=${encodeURIComponent(bodyPart)}${subjectPart}`

    logShareClick(ShareIconType.Email)
    window.open(url, '_self')
  }, [onClick])


  return (
    <FaEnvelope size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Share by Email'/>
  )
}