import React from "react"
import * as styles from "./RaisedButton.module.scss"

interface RaisedButtonProps {
  className?: string,
  onClick: VoidFunction,
  style?: React.CSSProperties
}
export const RaisedButton: React.FC<RaisedButtonProps> = ({ children, className, onClick, style }) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.RaisedButton}${(className) ? ` ${className}` : ''}`}
      style={style}
    >
      {children}
    </button>
  )
}