import { ShareIconType } from "./ShareIconType"
import { IMetadata } from "../../model/IMetadata"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FacebookShareIcon } from "./icon/FacebookShareIcon"
import { LinkedinShareIcon } from "./icon/LinkedinShareIcon"
import { ClientOnlyRenderable } from "../ClientOnlyRenderable"
import { PinterestShareIcon } from "./icon/PinterestShareIcon"
import { getAbsoluteUrl_CLIENT_ONLY } from "../../util/util"
import { RedditShareIcon } from "./icon/RedditShareIcon"
import { TwitterShareIcon } from "./icon/TwitterShareIcon"
import { EmailShareIcon } from "./icon/EmailShareIcon"

interface SocialIconProps {
  linkType: ShareIconType,
  size: number,
  pageSeo: IMetadata
}
interface SocialIconData {
  strapi: {
    globalConfig: {
      Metadata: IMetadata,
      titleSuffix: string
    }
  }
}
export const SocialIcon: React.FC<SocialIconProps> = ({linkType, size, pageSeo}) => {
  const data = useStaticQuery<SocialIconData>(query)
  const {Metadata: defaultMetadata, titleSuffix} = data.strapi.globalConfig

  const shareImageUrlPath = pageSeo.shareImage?.urlSharp.staticURL ?? defaultMetadata.shareImage?.urlSharp.staticURL
  let shareTitle = pageSeo.metaTitle ?? defaultMetadata.metaTitle ?? undefined
  if (shareTitle !== undefined) {
    shareTitle = `${shareTitle} | ${titleSuffix}`
  }

  const shareDescription = pageSeo.metaDescription ?? defaultMetadata.metaDescription ?? undefined

  switch (linkType) {
    case ShareIconType.Facebook:
      return <FacebookShareIcon size={size} />
    case ShareIconType.Linkedin:
      return <LinkedinShareIcon size={size} />
    case ShareIconType.Pinterest:
      if (shareImageUrlPath === undefined) {
        return <></>
      } else {
        return (
          <ClientOnlyRenderable>
            {() => (
              <PinterestShareIcon size={size} mediaUrl={getAbsoluteUrl_CLIENT_ONLY(shareImageUrlPath)} description={pageSeo.metaDescription ?? undefined} />
            )}
          </ClientOnlyRenderable>
        )
      }
    case ShareIconType.Reddit:
      return <RedditShareIcon size={size} title={shareTitle}/>
    case ShareIconType.Twitter:
      return <TwitterShareIcon size={size} text={shareDescription ?? shareTitle}/>
    case ShareIconType.Email:
      return <EmailShareIcon size={size} subject={shareTitle} body={shareDescription}/>
    default:
      throw new Error(`Unsupported share icon type: ${linkType}`)
  }
}

const query = graphql`
  query {
    strapi {
      globalConfig {
        titleSuffix
        Metadata {
            metaTitle
            metaDescription
            shareImage {
              url
              urlSharp {
                staticURL
              }
            }
          }
      }
    }
  }
`