import ReactDOM from "react-dom"

// Does some lovely startup DOM manipulation. Only import this if it's actually needed.
interface BodyStartPortalProps {
  key?: string | null | undefined
}
export const BodyStartPortal: React.FC<BodyStartPortalProps> = ({children, key}) => {
  /// Portals children to be appended to startPortalDiv
  const startPortalDiv = document.getElementById("prebody_portal")
  if (startPortalDiv === null) {
    throw new Error("Failed to find BodyStartPortal element.")
  }
  return ReactDOM.createPortal(children, startPortalDiv, key)
}
