import { Size } from "../util/size"
import { useEffect, useState } from "react"

const DEFAULT_WINDOW_SIZE: Size = {
  width: 1600,
  height: 900
}
export const useWindowSize = (defaultSize: Size = DEFAULT_WINDOW_SIZE): Size => {
  const [currentSize, setCurrentSize] = useState<Size>(defaultSize)

  useEffect(() => {
    const onWindowResize = () => {
      setCurrentSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener("resize", onWindowResize)
    onWindowResize()

    // Clean up when we unmount
    return () => window.removeEventListener("resize", onWindowResize)
  }, [])

  return currentSize
}

export const useWindowWidth = (): number => {
  return useWindowSize().width
}

export const useWindowHeight = (): number => {
  return useWindowSize().height
}