import React from "react"
import { ShareIconType } from "./ShareIconType"
import { IMetadata } from "../../model/IMetadata"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./SocialIcons.module.scss"
import { SocialIcon } from "./SocialIcon"

interface SocialIconsProps {
  pageSeo: IMetadata,
}
interface SocialIconsData {
  strapi: {
    globalConfig: {
      socialLinks: { type: ShareIconType }[]
    }
  }
}
export const SocialIcons: React.FC<SocialIconsProps> = ({pageSeo}) => {
  const data = useStaticQuery<SocialIconsData>(query)
  const {socialLinks} = data.strapi.globalConfig

  return (
    <div className={styles.sectionIcons}>
       {(socialLinks.map(({type: linkType}, index) => (
         <SocialIcon key={`SocialIcon-${linkType}-${index}`} pageSeo={pageSeo} linkType={linkType} size={18}/>
       )))}
    </div>
  )
}

const query = graphql`
  query {
    strapi {
      globalConfig {
        socialLinks {
          type
        }
      }
    }
  } 
`