import React, { useCallback } from "react"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { ShareIconType } from "../ShareIconType"

interface TwitterShareIconProps extends ShareIconProps {
  text?: string
}
export const TwitterShareIcon: React.FC<TwitterShareIconProps> = ({size = 20, className, onClick, text, scaleOnTouchScreen=true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    const currentUrlEncoded = encodeURIComponent(window.location.origin)
    let titlePart = ''
    if (text) {
      titlePart = `&text=${encodeURIComponent(text)}`
    }

    const url = `https://twitter.com/intent/tweet?url=${currentUrlEncoded}${titlePart}`

    logShareClick(ShareIconType.Twitter)
    window.open(url, '_blank')
  }, [onClick])

  return (
    <FaTwitter size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Share on Twitter'/>
  )
}