import { useWindowSize } from "./window_size"

export enum ResponsiveState {
  PHONE,
  TABLET,
  LAPTOP,
  DESKTOP
}

export const useResponsiveState = (): ResponsiveState => {
  const windowSize = useWindowSize()

  if (windowSize.width <= 400) {
    return ResponsiveState.PHONE
  }
  else if (windowSize.width <= 868) {
    return ResponsiveState.TABLET
  }
  else if (windowSize.width <= 1288) {
    return ResponsiveState.LAPTOP
  }
  else {
    return ResponsiveState.DESKTOP
  }
}