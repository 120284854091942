import React from "react"
import { IPhoneNumber } from "../model/IPhoneNumber"

interface BasicPhoneDetailsProps {
  className?: string,
  localNumber: IPhoneNumber,
  nationalNumber: IPhoneNumber
}
export const BasicPhoneDetails: React.FC<BasicPhoneDetailsProps> = ({className, localNumber, nationalNumber}) => {
  return (
    <div className={className}>
      <a href={`tel:${localNumber.callableNumber}`}>{localNumber.userVisible}</a> or call free <a href={`tel:${nationalNumber.callableNumber}`}>{nationalNumber.userVisible}</a>
    </div>
  )
}