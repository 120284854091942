import React, { useCallback } from "react"
import { mergeClassNames } from "../../../util/util"
import * as styles from "./common.module.scss"
import {getActualSize, logShareClick, ShareIconProps} from "./common"
import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest"
import { ShareIconType } from "../ShareIconType"

interface PinterestShareIconProps extends ShareIconProps {
  mediaUrl: string,
  description?: string
}
export const PinterestShareIcon: React.FC<PinterestShareIconProps> = ({size = 20, className, onClick, mediaUrl, description, scaleOnTouchScreen=true}) => {
  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick()
    }

    const currentUrlEncoded = encodeURIComponent(window.location.origin)
    let descriptionPart = ''
    if (description) {
      descriptionPart = `&description=${encodeURIComponent(description)}`
    }

    const url = `https://www.pinterest.nz/pin-builder/?url=${currentUrlEncoded}&media=${encodeURIComponent(mediaUrl)}${descriptionPart}&method=button`

    logShareClick(ShareIconType.Pinterest)
    window.open(url, '_blank')
  }, [onClick])

  return (
    <FaPinterest size={getActualSize(size, scaleOnTouchScreen)} className={mergeClassNames(styles.Icon, className)} onClick={onClickCallback} title='Save on Pinterest'/>
  )
}