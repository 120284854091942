import React, { useEffect, useState } from "react"

interface ClientOnlyRenderableProps {
  serverSideRenderable?: React.ReactNode,
  children: (() => React.ReactNode) | React.ReactNode
}
export const ClientOnlyRenderable: React.FC<ClientOnlyRenderableProps> = ({children, serverSideRenderable}) => {
  const [showChild, setShowChild] = useState(false)

  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return (serverSideRenderable !== undefined) ? <>{serverSideRenderable}</> : null
  }
  else {
    return (
      <>
        {(children instanceof Function) ? children() : children}
      </>
    )
  }
}