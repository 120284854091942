import React from "react"
import { mergeClassNames } from "../../util/util"
import * as styles from "./MyKindoLogin.module.scss"
import { RaisedButton } from "./RaisedButton"

interface MyKindoButtonProps {
  onClick: VoidFunction,
  className?: string
}
export const MyKindoButton: React.FC<MyKindoButtonProps> = ({children, onClick, className}) => {
  return (
    <RaisedButton
      onClick={onClick}
      className={mergeClassNames(styles.MyKindoLogin, styles.minimised, className)}
    >
      {children}
    </RaisedButton>
  )
}