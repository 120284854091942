import * as React from "react"

import * as styles from "./Section.module.scss"
import { mergeClassNames } from "../../util/util"

interface SectionProps {
  className?: string
}
const Section: React.FC<SectionProps> = ({ children, className }) => {
  return <div className={mergeClassNames(styles.sectionContainer, className)}>{children}</div>
}

export default Section
