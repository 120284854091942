import React from "react"
import * as styles from "./MyKindoLogo.module.scss"
import { mergeClassNames } from "../util/util"

interface MyKindoLogoProps {
  fontSize?: number,
  className?: string
}
export const MyKindoLogo: React.FC<MyKindoLogoProps> = ({fontSize = 19, className}) => {
  return (
    <span className={mergeClassNames(styles.MyKindoLogo, className)} style={{fontSize: fontSize}}>
      <span className={styles.my}>my</span>
      <span className={styles.kindo}>kindo</span>
    </span>
  )
}